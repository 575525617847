// Iframe documentation
// http://bitbucket.selectrehab.com/projects/REC/repos/recruiting/browse/README.md
const iframe = document.getElementById('quick_apply_form')

window.addEventListener('message', e => {
  // Return unless iframe is from our domains
  const urls = ["https://recruiting.selectrehab.com", "https://recruiting-staging.selectrehab.us"]

  if (!urls.includes(e.origin)) return;

  const msg = e['data']

  if (msg.event === 'DOMContentLoaded') {
    iframe.contentWindow.postMessage({'event': 'getHeight'}, e.origin)
  } else if (msg.event === 'getHeight') {
    $(iframe).height(msg.height)
  }
}, false)
